<template>
  <a-card :bordered="false">
    <div>
      <a-page-header
        v-if="orderInfo"
        style="border: 1px solid rgb(235, 237, 240); margin-bottom: 35px"
        title="订单详情"
        @back="() => $router.go(-1)"
      >
        <a-divider orientation="left">
          {{ orderInfo.order_no }}
        </a-divider>
        <a-row :gutter="16">
          <a-col :md="6" :sm="8" span="4">
            <span>收货人：{{ orderInfo.receiving_name }}</span>
          </a-col>
          <a-col :md="6" :sm="8" span="4">
            <span>收货人电话：{{ orderInfo.receiving_phone }}</span>
          </a-col>
          <a-col :md="6" :sm="8" span="4">
            <span v-if="orderInfo.actual_paid">实付款：￥{{ (orderInfo.actual_paid / 100).toFixed(2) | numberFormat }}</span>
          </a-col>
          <a-col :md="6" :sm="8" span="4">
            <span>订单状态：{{ this.$Dictionaries.applet_order_status[orderInfo.status] }}</span>
          </a-col>
        </a-row>
        <a-row :gutter="16" style="margin-top: 10px;">
          <a-col :md="12" :sm="8" span="4">
            <span>地址：{{ orderInfo.province }}{{ orderInfo.city }}{{ orderInfo.county }}{{ orderInfo.address }}</span>
          </a-col>
          <a-col :md="6" :sm="8" span="4">
            <span>创建时间：{{ orderInfo.create_time }}</span>
          </a-col>
          <a-col :md="6" :sm="8" span="4">
            <span>供应商：{{ this.$route.query.supplier }}</span>
          </a-col>
        </a-row>
      </a-page-header>
      <div>
        <s-table
          ref="table"
          size="small"
          rowKey="id"
          :loading="loading"
          :columns="columns"
          :showPagination="false"
          :data="loadData"
          v-if="is_show"
        >
          <span slot="serial" slot-scope="text, record, index">
            {{ index + 1 }}
          </span>
          <div slot="price_render" slot-scope="text" style="min-width: 110px">
            {{ (text / 100).toFixed(2) }}
          </div>
          <span slot="img" slot-scope="img">
            <a-avatar
              v-if="img"
              slot="img"
              :src="img"
              shape="square"
              @click="openPreview(img)"
            />
          </span>
        </s-table>
        <div v-if="!is_show" style="padding-left: 20px; padding-right: 20px">
          <div v-for="child_order in orderInfo.child_order" :key="child_order.id">
            <a-table :columns="columns" :data-source="child_order.goods_list" :pagination="false">
              <span slot="serial" slot-scope="text, record, index">
                {{ index + 1 }}
              </span>
              <div slot="price_render" slot-scope="text" style="min-width: 110px">
                {{ (text / 100).toFixed(2) | numberFormat }}
              </div>
              <span slot="img" slot-scope="img">
                <a-avatar
                  v-if="img"
                  slot="img"
                  :src="img"
                  shape="square"
                  @click="openPreview(img)"
                />
              </span>
              <template slot="title">
                <div>
                  <span style="padding-left: 15px;">
                    子订单号：{{ child_order.order_no }}
                  </span>
                  <span style="padding-left: 25px;">
                    供应商：{{ child_order.supplier }}
                  </span>
                </div>
              </template>
              <template slot="footer">
                <div style="padding-right: 15px; text-align: right">
                  <span>
                    共{{ orderGoodsCount(child_order) }}件商品，金额:￥{{ (child_order.actual_paid / 100).toFixed(2) | numberFormat }}
                  </span>
                </div>
              </template>
            </a-table>
          </div>
        </div>
      </div>

      <a-modal v-model="previewvisible" title="预览" width="350px" :footer="null">
        <img alt="example" style="width: 300px; margin: 0 auto" :src="previewurl" />
      </a-modal>
    </div>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import { orderDetail } from '@/api/applet_shopmall_order'

export default {
  name: 'TableList',
  components: {
    STable
  },
  props: {},
  data () {
    return {
      loading: false,
      confirmLoading: false,
      orderInfo: undefined,
      is_show: true,
      // 创建窗口控制
      visible: false,
      mdl: {},
      // 创建窗口控制
      dictionaries: Dictionaries,
      // 高级搜索 展开/关闭
      previewvisible: false,
      previewurl: '',
      // 表头
      columns: [
        {
          title: '序号',
          align: 'center',
          width: '70px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '商品图',
          dataIndex: 'picture',
          width: '100px',
          scopedSlots: { customRender: 'img' }
        },
        {
          title: '微信展示名称',
          align: 'center',
          dataIndex: 'brief'
        },
        {
          title: '商品名称',
          align: 'center',
          dataIndex: 'name'
        },
        {
          title: '成本',
          align: 'center',
          dataIndex: 'cost',
          scopedSlots: { customRender: 'price_render' }
        },
        {
          title: '毛利',
          align: 'center',
          dataIndex: 'gross_profit',
          scopedSlots: { customRender: 'price_render' }
        },
        {
          title: '优惠',
          align: 'center',
          dataIndex: 'preferential',
          scopedSlots: { customRender: 'price_render' }
        },
        {
          title: '单价',
          align: 'center',
          dataIndex: 'price',
          scopedSlots: { customRender: 'price_render' }
        },
        {
          title: '数量',
          align: 'center',
          dataIndex: 'count'
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return orderDetail(this.$route.query.id)
          .then((res) => {
            const localData = { entries: res.data.goods_list }
            this.orderInfo = res.data
            if (this.orderInfo.goods_list && this.orderInfo.goods_list.length > 0) {
              this.is_show = true
            } else {
              this.is_show = false
            }
            return localData
          })
      }
    }
  },
  methods: {
    openPreview (url) {
      this.previewvisible = true
      this.previewurl = url
    }
  },
  computed: {
    orderGoodsCount () {
      return order => order.goods_list.reduce((sum, item) => sum + item.count, 0)
    }
  }
}
</script>
